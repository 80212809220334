import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Nuvo from "../../components/Portfolio/Nuvo"

const NuvoPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Home" keywords={[`BMR`, `labels`]} />
    <Nuvo />
  </Layout>
)

export default NuvoPage
